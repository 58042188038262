import React from 'react'
// import './businessCard.scss';
import ZhihuIcon from './../../assests/icons/zhihu.svg'
import GithubIcon from './../../assests/icons/github.svg'
import TwitterIcon from './../../assests/icons/twitter.svg'
import { useStaticQuery, graphql } from 'gatsby'
import { useStyles } from './style'

const navigateTo = (url) => {
    return (e) => window.open(url)
}

const query = graphql`
    query {
        site {
            siteMetadata {
                intro {
                    name
                    bio
                    avatar
                }
                socical {
                    zhihu
                    github
                    twitter
                }
            }
        }
    }
`
export const BusinessCard = (props) => {
    const data = useStaticQuery(query)
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className="avatar">
                <img src={data.site.siteMetadata.intro.avatar} />
            </div>
            <div className="about">
                <div className="info_text">
                    <div className="author_name">
                        {data.site.siteMetadata.intro.name}
                    </div>
                    <div className="bio">
                        {data.site.siteMetadata.intro.bio}
                    </div>
                </div>
                <div className="socical_links">
                    <div
                        className="link_icon zhihu"
                        onClick={navigateTo(
                            data.site.siteMetadata.socical.zhihu
                        )}
                    >
                        <ZhihuIcon />
                        <div className="follower_count">+{1}</div>
                    </div>
                    <div
                        className="link_icon github"
                        onClick={navigateTo(
                            data.site.siteMetadata.socical.github
                        )}
                    >
                        <GithubIcon />
                        <div className="follower_count">+{1}</div>
                    </div>
                    <div
                        className="link_icon twitter"
                        onClick={navigateTo(
                            data.site.siteMetadata.socical.twitter
                        )}
                    >
                        <TwitterIcon />
                        <div className="follower_count">+{1}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

BusinessCard.defaultProps = {
    // horizontal: false,
    // userName: 'UserName',
    // links: {
    //     zhihu: {
    //         url: 'https://zhihu.com/people/techmoe',
    //         follower_num: 11
    //     },
    //     github: {
    //         url: 'https://zhihu.com/people/techmoe',
    //         follower_num: 992
    //     },
    //     twitter: {
    //         url: 'https://zhihu.com/people/techmoe',
    //         follower_num: 993
    //     }
    // }
}

export default BusinessCard
