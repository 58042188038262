import React from 'react'
import Article, { Title } from '../../components/article/article'
import AdjacentArticle from '../../components/adjacentArticle/adjacentArticle'
import Comment from '../../components/comment/comment'
import { BusinessCard } from '../../components/businessCard/businessCard'
import { withLayout } from '../../components/layout/layout'
import hljs from 'highlight.js'
import { useEffect } from 'react'
import SEO from '../../components/SEO'

const ArticleTemplate = (props) => {
    useEffect(() => {
        document.querySelectorAll('pre code').forEach((block) => {
            // console.log(hljs)
            hljs.highlightBlock(block)
            // window.hljs = hljs
        })
        document.title = `${pageContext.frontmatter.title} - lighthouse: techmoe的blog`
    }, [])
    const { pageContext, uri, adjacentArticles } = props
    return (
        <div className="article_page">
            <SEO
                title={pageContext.frontmatter.title}
                image={
                    pageContext.frontmatter.featured_image ||
                    `https://picsum.photos/seed/${pageContext.frontmatter.slug}/500/350`
                }
                isArticle={true}
            />
            <Title
                title={pageContext.frontmatter.title}
                // TODO
                backgroundImage={
                    pageContext.frontmatter.featured_image ||
                    `https://picsum.photos/seed/${pageContext.frontmatter.slug}/500/350`
                }
                url={uri}
            />
            <BusinessCard horizontal={true} />
            <Article html={pageContext.html} />

            <AdjacentArticle {...pageContext.adjacentArticles} />
            <Comment
                title={pageContext.frontmatter.title}
                identifier={pageContext.frontmatter.slug}
                // FIXME: hardcoded value
                url={`https://tty.moe/blog/${pageContext.frontmatter.slug}`}
            />
        </div>
    )
}

export default withLayout(ArticleTemplate)
