import React from 'react'
// import './adjacentArticle.scss';
import { navigate } from 'gatsby'
import { createUseStyles } from 'react-jss'
import { theme } from '../../theme'

const useStyles = createUseStyles({
    root: {
        border: {
            width: '1px',
            color: theme.palette.primary,
            style: 'solid',
        },
        padding: theme.spacing(1),
        display: 'flex',
        cursor: 'pointer',
    },
    prev: {
        width: '50%',
        border: {
            width: '1px',
            color: theme.palette.primary,
            style: 'none solid none none',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingRight: theme.spacing(2),
    },
    next: {
        width: '50%',
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
    },
})

const AdjacentArticle = (props) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {/* TODO: 这里结构安排不合理 */}
            <div
                className={classes.prev}
                onClick={(e) =>
                    props.prev
                        ? navigate(`/blog/${props.prev.slug}`)
                        : e.preventDefault()
                }
            >
                上一篇文章
                <div className="title">
                    {props.prev ? props.prev.title : '没有了'}
                </div>
            </div>
            <div
                className={classes.next}
                onClick={(e) =>
                    props.next
                        ? navigate(`/blog/${props.next.slug}`)
                        : e.preventDefault()
                }
            >
                下一篇文章
                <div className="title">
                    {props.next ? props.next.title : '没有了'}
                </div>
            </div>
        </div>
    )
}

AdjacentArticle.defaultProps = {
    prev: {
        title: '没有了',
        slug: '#',
    },
    next: {
        title: '没有了',
        slug: '#',
    },
}

export default AdjacentArticle
